.layout {
  .logo {
    height: fit-content;
    margin-left: 40px;
    width: 200px;

    &:first-child {
      margin-left: 0;
    }
  }
}