.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-group, button {
    margin-top: 5px;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    label {
      margin-right: 5px;
    }

    .select-virtualized {
      width: 300px;
    }
  }

}